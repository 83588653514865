<template>
  <EntityList
    :config="config"
    :columns="columns"
    :form-fields="formFields"
    :formatted-columns="formattedColumns"
    :allow-duplicate="false"
    details-view
    authority="Measurements_Docking"
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'
import storeModule from './storeModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      vesselOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'dockings',
        endpoint: 'dockings',
        route: 'dockings',
        title: {
          single: this.$i18n.t('Docking'),
          plural: this.$i18n.t('Dockings'),
        },
      }
    },
    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'vessels', label: this.$t('Vessels') },
        { key: 'startDate', label: this.$t('Start Date'), sortable: true },
        { key: 'endDate', label: this.$t('End Date'), sortable: true },
        { key: 'actions', label: this.$t('actions') },
      ]
    },
    formattedColumns() {
      return [
        { key: 'vessels', type: 'list' },
        { key: 'startDate', type: 'datetime' },
        { key: 'endDate', type: 'datetime' },
      ]
    },
    formFields() {
      return [
        {
          id: 'vessels',
          object: true,
          type: 'selectmultiple',
          label: this.$t('Vessels'),
          options: this.vesselOptions,
          required: true,
        },
        // {
        //   id: 'startDate',
        //   type: 'datetime',
        //   label: this.$t('Start Date'),
        //   required: true,
        // },
        {
          id: 'comments',
          type: 'textarea',
          label: this.$t('comments'),
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchVessels').then((response) => {
      this.vesselOptions = response.data
    })
  },
}
</script>
